import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "core-js/modules/es.array.push.js";
import { taskPerformInfoNew } from '@/api/wxproject';
export default {
  data() {
    return {
      currentPage: 1,
      // page:1,
      success: false,
      active: 0,
      type1: '',
      activeName: '',
      pageData: []
    };
  },
  mounted() {
    console.log(this.$route.query);
    document.title = '触点活跃详情';
    this.activeName = this.$route.query.activeName;
    this.status1 = this.$route.query.status1;
    this.type1 = this.$route.query.type1;
    this.getDate();
  },
  methods: {
    getDate() {
      taskPerformInfoNew({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        orgTerminalCode: this.$route.query.orgTerminalCode,
        userCode: this.$route.query.userCode,
        planCode: this.$route.query.planCode,
        date: this.$route.query.date,
        batchCode: this.$route.query.batchCode
      }).then(res => {
        console.log('res: ', res);
        this.pageData = res.data.data;
      });
    },
    changeactive(index) {
      if (this.active === index) {
        return;
      }
      this.active = index;
      this.currentPage = 1;
    },
    changepage() {
      this.page = this.currentPage;
    },
    goback1() {
      this.$router.push({
        path: '/ActiveDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type,
          activeName: this.activeName,
          date: this.$route.query.date,
          status: this.$route.query.status,
          searchname: this.$route.query.searchname,
          province: this.$route.query.province,
          city: this.$route.query.city,
          area: this.$route.query.area
        }
      });
    },
    goback2() {
      // window.history.back();
      this.$router.go(-1);
    },
    preview(src) {
      _ImagePreview([src]);
    }
  }
};